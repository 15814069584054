import { TimeUnit } from "../utils/dates";
import { satisfies } from "../utils/typescript";
import { AnalyticsCategory, AnalyticsUnit } from "./Analytics.types";

// `satisfies` so object always gets new keys as they
// come in, but typed to be partial outside of that
// so we always `?` in implementation to avoid
// run-time errors.
export const ANALYTICS_CATEGORY_META: { [CATEGORY in AnalyticsCategory]?: { label: string } } = satisfies<
  { [CATEGORY in AnalyticsCategory]: { label: string } }
>()({
  ONE_ON_ONE: { label: "One-on-ones" },
  TRAVEL: { label: "Travel time" },
  UNKNOWN: { label: "Meetings" },
  VACATION: { label: "Vacation time" },
  BREAK: { label: "Break time" },
  DEEP_WORK: { label: "Deep work" },
  EXTERNAL_MEETING: { label: "External meetings" },
  PERSONAL: { label: "Personal time" },
  SHALLOW_WORK: { label: "Shallow work" },
  TEAM_MEETING: { label: "Team meetings" },
});

export const ANALYTICS_TIME_UNIT_CONVERSION_MAP = satisfies<{ [UNIT in AnalyticsUnit]?: TimeUnit }>()({
  DURATION_MINUTES: "min",
});
