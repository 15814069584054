import { ReactNode } from "react";
import {
  AnalyticsBucket,
  AnalyticsBucketTypeMap,
  AnalyticsCategory,
  AnalyticsGroupingInterval,
  AnalyticsUnit,
} from "../../reclaim-api/Analytics.types";
import { makeValidItemFilter } from "../../utils/arrays";
import { TIME_UNIT_META, TimeUnit, convertTimeUnit } from "../../utils/dates";
import { roundToDecimalPlaces } from "../../utils/numbers";
import { evalOnce } from "../../utils/objects";
import { pluralize } from "../../utils/strings";
import { ANALYTICS_PAGE_BUCKET_META } from "./AnalyticsPage.consts";
import { AnalyticsPageCtxData, AnalyticsPageMeta } from "./AnalyticsPage.types";

const ANALYTICS_UNIT_TO_TIME_UNIT: { [UNIT in AnalyticsUnit]?: TimeUnit } = {
  DURATION_MINUTES: "min",
};

export const getAnalyticsPageGroupingInterval = (start: Date, end: Date): AnalyticsGroupingInterval => {
  const diff = Math.abs(end.getTime() - start.getTime());
  const days = convertTimeUnit(diff, "ms", "day");
  return days >= 29 ? "WEEKLY" : "DAILY";
};

export const renderAnalyticsAxis = <BUCKET extends AnalyticsBucket>(
  bucket: BUCKET,
  value: AnalyticsBucketTypeMap[BUCKET],
  ctx: AnalyticsPageCtxData
): ReactNode => {
  const { renderAxis, renderString } = (ANALYTICS_PAGE_BUCKET_META[bucket] || {}) as AnalyticsPageMeta<BUCKET>;
  if (renderAxis) return renderAxis(value, ctx);
  if (renderString) return renderString(value, ctx);
  return value.toString();
};

export const analyticsPageTimeConversion = (value: number, analyticsUnit: AnalyticsUnit, outputUnit: TimeUnit) => {
  const inputUnit = ANALYTICS_UNIT_TO_TIME_UNIT[analyticsUnit];
  return inputUnit ? convertTimeUnit(value, inputUnit, outputUnit) : value;
};

export const analyticsPageTimeStr = (
  value: number,
  analyticsUnit: AnalyticsUnit,
  outputUnit: TimeUnit,
  shortLabel?: boolean
) => {
  const num = analyticsPageTimeConversion(value, analyticsUnit, outputUnit);
  const meta = TIME_UNIT_META[outputUnit];
  return `${roundToDecimalPlaces(num, 2, true)} ${pluralize(num, meta.shortLabel)}`;
};

export const renderAnalyticsUnit = (value: number, unit: AnalyticsUnit, outputUnit: TimeUnit, shortLabel?: boolean) => {
  switch (unit) {
    case "DURATION_MINUTES":
      return analyticsPageTimeStr(value, unit, outputUnit, shortLabel);
    case "PERCENTAGE":
      return `${value}%`;
    default:
      return value.toString();
  }
};

export const getMeetingsCategoryFilter = evalOnce(() =>
  makeValidItemFilter<AnalyticsCategory>(["ONE_ON_ONE", "UNKNOWN", "EXTERNAL_MEETING", "TEAM_MEETING"])
);
