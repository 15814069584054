import { addDays, format, min } from "date-fns";
import { ANALYTICS_CATEGORY_META } from "../../reclaim-api/Analytics.consts";
import { AnalyticsBucket } from "../../reclaim-api/Analytics.types";
import { yyyyMmDdToDate } from "../../utils/dates";
import { AnalyticsPageMeta } from "./AnalyticsPage.types";

export const ANALYTICS_PAGE_BUCKET_META: {
  [BUCKET in AnalyticsBucket]?: AnalyticsPageMeta<BUCKET>;
} = {
  DATE: {
    convertForChartAxis: (yyyymmdd) => yyyyMmDdToDate(yyyymmdd).getTime(),
    renderString: (yyyymmdd) => {
      const d = yyyyMmDdToDate(yyyymmdd);
      return `${format(d, "MMM")}, ${format(d, "dd")}`;
    },
    renderAxis: (yyyymmdd) => {
      const d = yyyyMmDdToDate(yyyymmdd);

      return (
        <>
          <tspan x={0}>{format(d, "MMM")}</tspan>
          <tspan x={0} dy={15}>
            {format(d, "dd")}
          </tspan>
        </>
      );
    },
    axisType: "number",
    axisScale: "time",
  },
  WEEK_OF: {
    convertForChartAxis: (yyyymmdd) => yyyyMmDdToDate(yyyymmdd).getTime(),
    renderString: (ms) => {
      const d = yyyyMmDdToDate(ms);
      return `${format(d, "MMM")}, ${format(d, "dd")}`;
    },
    renderAxis: (yyyymmdd, { dateRange: [, end] }) => {
      end = end || new Date();
      const d = yyyyMmDdToDate(yyyymmdd);
      const endD = min([addDays(d, 7), end]);

      return (
        <>
          <tspan x={0}>{format(d, "MMM")}</tspan>
          <tspan x={0} dy={15}>
            {format(d, "dd")} - {format(endD, "dd")}
          </tspan>
        </>
      );
    },
    axisType: "number",
    axisScale: "time",
  },
  ANALYTICS_CATEGORY: {
    renderString: (category) => ANALYTICS_CATEGORY_META[category]?.label || category,
  },
};
